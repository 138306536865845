const AppliUI = () => {
    return(
        <div className='appli_ui_container'>
            <span className='appli_ui_container_title'>Auto Loan Calc<span className='appli_ui_container_title_you'>you</span>lator</span>
            <div className='appli_types_loans'>
                <div className='appli_type_loans_selected'><span>Auto Loan</span></div>
                <div className='appli_types_loans_buttons'>Home Loan<br/><span style={{fontSize: '12px'}}>coming soon</span></div>
                <div className='appli_types_loans_buttons'>Personal Loan<br/><span style={{fontSize: '12px'}}>coming soon</span></div>
            </div>
            {/* <iframe className='appli_calculator_frame' src='http://localhost:3000'></iframe> */}
            <iframe className='appli_calculator_frame' src='https://calcyoulator.hiappli.com/embedded_calculator/?access_code=appli_ATPfqo601'></iframe>
            <span className='appli_estimate_condition'>The payment estimates above are for estimation purposes only. You still need to apply for the financial product and meet the requirements of the lender to qualify.</span>
        </div>
    )
}

export default AppliUI