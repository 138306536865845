import React from 'react';
import '../styles/calcyoulator.css';
import Header  from '../components/header';

import EstimatePayments from '../components/estimatePayments';
import AppliUI from '../components/appliUiContainer';
import HowItWorks from '../components/howItWorksContainer';
import AboutUs from '../components/aboutUsContainer';
import InTouch from '../components/getInTouch';
import Footer from '../components/footer';

const Main = () => {

    return (
        <div className='calcyoulator_main_layout'>
            <Header />
            <EstimatePayments />
            <AppliUI />
            <HowItWorks />
            <AboutUs />
            <InTouch />
            <Footer />
        </div>
    )
}

export default Main;