import logo from '../images/calcyoulator_2@4x1.png'
import companyName from '../images/calcyoulator.svg'

const HowItWorks = () => {
    return(
        <div id="calcyoulator_how_it_works" className='calcyoulator_how_it_works'>
            <span className='calcyoulator_how_it_works_title'>How it Works</span>
            <div className='calcyoulator_how_it_works_block'>
                <div className='calcyoulator_main_how_it_works_logo_container'>
                    <div className='calcyoulator_how_to_block_title'>
                        <img src={logo} alt='Calcyoulator Logo' title='Calcyoulator Logo'/>
                        <img className='calcyoulator_main_header_logo_name' src={companyName} alt='Calcyoulator Name' title='Calcyoulator Name'/>
                    </div>
                    <span className='calcyoulator_how_it_works_text'>Easy to use, no sign ups, and no personal data collected. Start calculating what fits into your budget right away. </span>
                </div>
                <div className='calcyoulator_how_it_works_block_car_house'/>
            </div>
            <div className='calcyoulator_how_it_works_block calcyoulator_how_it_works_block_reverse'>
                <div className='calcyoulator_main_how_it_works_logo_container'>
                    <div className='calcyoulator_how_to_block_title'>
                        <div className='calcyoulator_how_to_block_title_text'>Confidence Built-in</div>
                    </div>
                    <span className='calcyoulator_how_it_works_text'>We use a proprietary AI confidence model to evaluate the likelihood you will qualify for the loan or financial service you want. This estimate gives you confidence in your payment calculation before you ever apply.</span>
                </div>
                <div className='calcyoulator_how_it_works_block_happy'/>
            </div>
            <div className='calcyoulator_how_it_works_block'>
                <div className='calcyoulator_main_how_it_works_logo_container'>
                    <div className='calcyoulator_how_to_block_title'>
                        <div className='calcyoulator_how_to_block_title_text'>Generative AI Lender</div>
                    </div>
                    <span className='calcyoulator_how_it_works_text'>We integrated our patent pending calculator with ChatGPT and trained it to act like a lender so you can get the information you need as fast as possible without using contact forms, emails, delayed SMS texts, and even the dreaded phone call.</span>
                </div>
                <div className='calcyoulator_how_it_works_block_pondering'/>
            </div>
        </div>
    )
}

export default HowItWorks;