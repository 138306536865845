import logo from '../images/calcyoulator_2@4x1.png'
import companyName from '../images/calcyoulator.svg'

const Footer = () => {
    return (
        <div>
            <div id="calcyoulator_footer" className='calcyoulator_footer'>
                <div className='calcyoulator_footer_container'>
                    <div id="calcyoulator_footer_logo_container" className='calcyoulator_footer_logo_container'>
                        <div className='calcyoulator_main_header_logo_container calcyoulator_main_header_logo_container_on_footer'>
                            <img src={logo} alt='Calcyoulator Logo' title='Calcyoulator Logo'/>
                            <img className='calcyoulator_main_header_logo_name' src={companyName} alt='Calcyoulator Name' title='Calcyoulator Name'/>
                        </div>
                        <span className='footer_powered_by'>powered by Appli, Inc.</span>
                    </div>
                    <div className='calcyoulator_footer_links_container'>
                        <a className='calcyoulator_footer_links' href='https://www.calcyoulator.com/privacy'>
                            Privacy Policy
                        </a>
                        <a className='calcyoulator_footer_links' href='https://www.hiappli.com'>
                            Appli
                        </a>
                    </div>
                    
                </div>
            </div>
            <div className='calcyoulator_improvements calcyoulator_copyright_container'>
                <span>© Appli, Inc. All rights reserved 2024</span>
            </div>
        </div>
    )
}

export default Footer