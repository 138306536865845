import logo from '../images/calcyoulator_2@4x1.png'
import companyName from '../images/calcyoulator.svg'

const Header = () => {
    
    const scroll = (elementId : string) => {
        let element = document.getElementById(elementId);
        element?.scrollIntoView({ behavior: 'smooth' });
    }
    
    return (
        <div className='calcyoulator_main_header'>
            <div className='calcyoulator_main_header_logo_container'>
                <a href='http://www.calcyoulator.com'>
                    <div className='calcyoulator_main_header_logo_container'>
                        <img src={logo} alt='Calcyoulator Logo' title='Calcyoulator Logo'/>
                        <img className='calcyoulator_main_header_logo_name' src={companyName} alt='Calcyoulator Name' title='Calcyoulator Name'/>
                    </div>
                </a>
            </div>
            <div className='calcyoulator_main_header_links_container'>
                <span id='calcyoulator_main_header_link1' className='calcyoulator_main_header_links' onClick={() => scroll('calcyoulator_how_it_works')}>How it works</span>
                <span id='calcyoulator_main_header_link2' className='calcyoulator_main_header_links' onClick={() => scroll('calcyoulator_about_text_container')}>About Us</span>
                <span id='calcyoulator_main_header_link3' className='calcyoulator_main_header_links' onClick={() => scroll('calcyoulator_get_in_touch')}>Get in Touch</span>
            </div>
        </div>
    )
}

export default Header;