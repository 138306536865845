import axios from "axios";

const InTouch = () => {


    const monrninstarUrl = process.env.REACT_APP_MORNINGSTAR_URL;

    const appliPost  = (api: string, json: any) => {
        axios.post(
            monrninstarUrl + '/' + api,
            json,
        )
    }

    const submitHandler = (event : any) => {
        event.preventDefault();
        let name = document.getElementById('calcyoulator_name') as HTMLInputElement;
        let email = document.getElementById('calcyoulator_email') as HTMLInputElement;
        let feedback = document.getElementById('calcyoulator_notes') as HTMLInputElement;

        appliPost('perspectiveLead', {
            name: name.value,
            email: email.value,
            feedback: feedback.value,
            contact: '1',
            state: ""
        })

        let form = document.getElementById("get_in_touch_form") as HTMLFormElement;
        form.reset();
    }

    return (
        <div id="calcyoulator_get_in_touch" className='calcyoulator_get_in_touch' onSubmit={(event) => submitHandler(event)}>
            <div className='calcyoulator_get_in_touch_text_container'>
                <span className='calcyoulator_how_it_works_title'>Get in touch</span>
                <span className='calcyoulator_how_it_works_sub_text'>We are always open to connecting with new customers building confidence in their loans, partners, and lenders. <br /> <br />Fill out the form and our team will be in touch.</span>
            </div>
            <div>
                <form id="get_in_touch_form" className='get_in_touch_form'>
                    <input id="calcyoulator_name" className='get_in_touch_form_input' type='text' placeholder='Name' name="name" required/>
                    <input id="calcyoulator_email" className='get_in_touch_form_input' type='email' placeholder='Email' name="email" required/>
                    <textarea id="calcyoulator_notes" className='get_in_touch_form_text_area' placeholder='Notes' name="notes"/>
                    <button className='get_in_touch_form_submit' type='submit'>Submit</button>
                </form>
            </div>
        </div>
    )
}

export default InTouch