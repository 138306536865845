import { useEffect } from "react";
import Footer from "../components/footer";
import Header from "../components/header";

const Privacy = () => {

    useEffect(() => {
        let link1 = document.getElementById('calcyoulator_main_header_link1');
        let link2 = document.getElementById('calcyoulator_main_header_link2');
        let link3 = document.getElementById('calcyoulator_main_header_link3');
        if(link1) link1.style.display = 'none';
        if(link2) link2.style.display = 'none';
        if(link3) link3.style.display = 'none';
    }, [])


    return (
        <div className='calcyoulator_main_layout'>
            <Header />
            <div className="calcyoulator_privacy_container">
                <div className="privacy_title_container">
                    <span className="privacy_title">Privacy Policy</span>
                    <span className="privacy_effective">Effective Date: 1/5/2024</span>
                </div>
                <div className="privacy_sub_text">
                    <span>This Privacy Policy describes how Appli, Inc ("we," "us," or "our") collects, uses, and shares information when you use our consumer tool available from our website calcyoulator.com (the "Service").</span>
                </div>
                <div>
                    <div className="privacy_sub_title">
                        <span>Information We Collect:</span>
                    </div>
                    <div className="privacy_sub_text">
                        <span>Personal Information: We may collect personal information, such as your name and email address, when you voluntarily provide it to us through the Service.</span>
                    </div>
                </div>
                <div>
                    <div className="privacy_sub_title">
                        <span>Usage Information:</span>
                    </div>
                    <div className="privacy_sub_text">
                        <span>We automatically collect certain information about your device and how you interact with the Service, including your IP address, browser type, and pages visited.</span>
                    </div>
                </div>
                <div>
                    <div className="privacy_sub_title">
                        <span>How We Use Your Information:</span>
                    </div>
                    <div className="privacy_sub_text">
                        <span>Providing and Improving the Service: We use the information we collect to operate and improve the Service, respond to your inquiries, and enhance user experience.</span>
                    </div>
                </div>
                <div>
                    <div className="privacy_sub_title">
                        <span>Communications:</span>
                    </div>
                    <div className="privacy_sub_text">
                        <span>If you provide your email address, we may use it to send you important updates or information related to the Service.</span>
                    </div>
                </div>
                <div>
                    <div className="privacy_sub_title">
                        <span>Sharing Your Information:</span>
                    </div>
                    <div className="privacy_sub_text">
                        <span>Third-Party Service Providers: We may share your information with third-party service providers that help us operate, maintain, and improve the Service.</span>
                    </div>
                </div>
                <div>
                    <div className="privacy_sub_title">
                        <span>Legal Compliance:</span>
                    </div>
                    <div className="privacy_sub_text">
                        <span>We may disclose your information when required by law or to protect our rights and interests.</span>
                    </div>
                </div>
                <div>
                    <div className="privacy_sub_title">
                        <span>Your Choices:</span>
                    </div>
                    <div className="privacy_sub_text">
                        <span>Opt-Out: You can opt-out of receiving non-essential communications from us by following the instructions in the communication.</span>
                    </div>
                </div>
                <div>
                    <div className="privacy_sub_title">
                        <span>Security:</span>
                    </div>
                    <div className="privacy_sub_text">
                        <span>We take reasonable measures to protect the confidentiality and security of your information. However, no method of transmission over the internet or electronic storage is 100% secure.</span>
                    </div>
                </div>
                <div>
                    <div className="privacy_sub_title">
                        <span>Changes to this Privacy Policy:</span>
                    </div>
                    <div className="privacy_sub_text">
                        <span>We may update this Privacy Policy from time to time. Any changes will be effective when posted on our website.</span>
                    </div>
                </div>
                <div>
                    <div className="privacy_sub_title">
                        <span>Contact Us:</span>
                    </div>
                    <div className="privacy_sub_text">
                        <span>If you have any questions about this Privacy Policy, please contact us at info@hiappli.com.</span>
                    </div>
                </div>
            </div>
            {/* <EstimatePayments />
            <AppliUI />
            <HowItWorks />
            <AboutUs />
            <InTouch /> */}
            <Footer />
        </div>
    )
}

export default Privacy;