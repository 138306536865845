import React from 'react';
import logo from './logo.svg';
import Main from './pages/main'
import Privacy from './pages/privacy';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Main/>} />
          <Route path="/privacy" element={<Privacy/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
