


const EstimatePayments = () => {
    return (
        <div className='calcyoulator_estimate_payments_container'>
            <div className='calcyoulator_estimate_payments_text_container'>
                <div className='calcyoulator_estimate_payments_text_1'>Estimate payments with a financial calculator built around you.</div>
                <div className='calcyoulator_estimate_payments_text_2'>Start calculating with confidence.</div>
            </div>
            <div className='calcyoulator_estimate_payments_image'/>
        </div>
    )
}

export default EstimatePayments