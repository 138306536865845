const AboutUs = () => {
    return (
        <div>
             <div id="calcyoulator_about" className='calcyoulator_about'>
                <div id="calcyoulator_about_text_container" className='calcyoulator_about_text_container'>
                    <span className='calcyoulator_about_title'>About Us</span>
                    <div className='calcyoulator_about_text'>We are inventors in the Financial Service space, and wanted to build something that gave communities access to Financial Tools that built confidence.</div>
                    <div className='calcyoulator_about_text'>We looked at calculators, AI, and typical branch interactions and thought they should be combined with some improvements.</div>
                </div>
            </div>
            <div id="calcyoulator_improvements" className='calcyoulator_improvements'>
                <div id="calcyoulator_about_text_container" className='calcyoulator_about_text_container'>
                    <span className='calcyoulator_about_title'>Improvements</span>
                    <div className="calcyoulator_improvements_sub_title"><span className="calcyoulator_improvements_emodicon">🫣</span><span className="calcyoulator_improvements_sub_title_text">Personal, stays personal</span></div>
                    <div className='calcyoulator_about_text'>What is personal, and private to you... stays with you. Did you know when you input your info into a form on other sites you become a lead that they get commission on? Well, here if you don’t want a loan or personal rates from lenders, we won’t give your info to them. In fact we won’t even ask for your info until you tell us you want to look for a lender.</div>
                    <div className="calcyoulator_improvements_sub_title"><span className="calcyoulator_improvements_emodicon">😅</span><span className="calcyoulator_improvements_sub_title_text">Show don’t tell</span></div>
                    <div className='calcyoulator_about_text'>Financial services can be scary, and lenders are more than willing to tell you all the reasons you should or should not qualify for a loan. Dealers are even worse. Everyone qualifies... even when they know you can’t or shouldn’t. Our tool uses AI to show you the confidence about different loan amounts/types you may qualify for, and gives an overview that you can check out or skip at any time.</div>
                    <div className="calcyoulator_improvements_sub_title"><span className="calcyoulator_improvements_emodicon">❤️</span><span className="calcyoulator_improvements_sub_title_text">No pressure, it is your financial future</span></div>
                    <div className='calcyoulator_about_text'>Feeling pressure to get a new loan is the last thing you should ever feel. Look for different services and adjust the inputs of the calculator as many times as you want. Once you feel comfortable and confident we can connect you with a potential lender or you can find someone you trust. It is up to you.</div>
                </div>
            </div>
        </div>
       
    )
}

export default AboutUs